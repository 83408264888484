import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import nanoid from "nanoid"

const spotifyLogin = () => {
    const nonce = 'spotify-' + nanoid()

    const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `)

    useEffect(() => {
        window.localStorage.setItem('oauth_nonce', nonce)
    })

    const authorizationURI = "https://accounts.spotify.com/authorize" +
        `?client_id=${process.env.GATSBY_SPOTIFY_CLIENT_ID}` +
        "&response_type=code" +
        `&redirect_uri=${data.site.siteMetadata.siteUrl}/listen` +
        "&scope=user-read-private user-read-email user-read-currently-playing playlist-read-private" +
        `&state=${nonce}`

    return (
        <>
            <h2>Spotify Login</h2>
            <a href={authorizationURI}>Login Here</a>
        </>
        )
}

export default spotifyLogin