import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SpotifyLogin from "../components/spotifyLogin"
import { initAuth } from "../app/services/auth"

const isBrowser = () => typeof window !== 'undefined'

const IndexPage = ({data}) => {
    if (isBrowser()) {
        initAuth()
    }

    return (
        <Layout>
            <SEO title="Home" />
            <h1>Hi people</h1>
            <p>Welcome to your new Gatsby site.</p>
            <p>Now go build something great.</p>
            <Link to="/page-2/">Go to page 2</Link>
            <SpotifyLogin/>
        </Layout>
    )
}

export default IndexPage
